(function($){
    $('.home-slider').slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        cssEase: 'linear'
    });
})(jQuery);
